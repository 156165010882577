"use client";
import { useEffect } from "react";

const GoogleTranslate = () => {
  useEffect(() => {
    // Function to initialize the Google Translate Element
    const initializeGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en", // Default language of your site
            includedLanguages: "mr,hi", // Include only the languages you want
            layout:
              window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          },
          "google_translate_element"
        );
      }
    };

    // Function to add the Google Translate script
    const addGoogleTranslateScript = () => {
      if (!document.getElementById("google-translate-script")) {
        const script = document.createElement("script");
        script.id = "google-translate-script";
        script.src =
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;
        document.body.appendChild(script);

        // Set a callback for the script to initialize when loaded
        window.googleTranslateElementInit = initializeGoogleTranslate;
      } else {
        // If the script is already added, initialize the widget
        initializeGoogleTranslate();
      }
    };

    addGoogleTranslateScript();
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
