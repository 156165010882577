import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/src/components/StickyHeader.jsx");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/shriraamastrologer.com/nextjs/src/styles/globals.scss");
